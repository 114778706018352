import React, { useState } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";

const features = [
  {
    title: "THEMED CAKES",
    img: "https://artcake-bucket.s3.amazonaws.com/themed.jpg",
  },
  {
    title: "COMPLEX 3D SHAPES",
    img: "https://artcake-bucket.s3.amazonaws.com/3dcake.png",
  },
  {
    title: "LATEST TRENDS",
    img: "https://artcake-bucket.s3.amazonaws.com/trend.png",
  },
  {
    title: "ANY OCCASION",
    img: "https://artcake-bucket.s3.amazonaws.com/occasion.jpg",
  },
  {
    title: "FULLY EADIBLE",
    img: "https://artcake-bucket.s3.amazonaws.com/eadible.jpg",
  },
  {
    title: "DETAILED DESIGN",
    img: "https://artcake-bucket.s3.amazonaws.com/detailed.jpg",
  },
];

export default function Features() {
  return (
    <Box
      sx={{
        backgroundColor: "#e8b169",
        pt: 5,
        pb: 5,
        pl: 2,
        pr: 2,
      }}
      height="auto"
    >
      {/* <Box
        sx={{
          // backgroundColor: "#110b09",
          backgroundImage: "linear-gradient(to right, #110b09 30%, #e8b169)",
        }}
        height="100vh"
      > */}
      <Grid container justifyContent="center" alignItems="center">
        {features.map((item) => (
          <Grid
            key={item.title}
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            sm={6}
            md={4}
            height={{
              xs: "16vh",
              sm: "30vh",
              md: "40vh",
            }}
            sx={{ p: 2 }}
          >
            <Box
              sx={{
                backgroundColor: "#110b09",
                height: "100%",
                width: "100%",
                borderRadius: 5,
              }}
            >
              <Grid height="100%" container sx={{ p: 1 }}>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  height={{ xs: "98%", sm: "70%", md: "77%" }}
                  xs={3}
                  sm={12}
                  // sx={{ marginTop: { md: 10 } }}
                >
                  <img
                    style={{ maxHeight: "90%" }}
                    src={item.img}
                    alt={item.name}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={9}
                  sm={12}
                  height={{ xs: "100%", sm: "30%", md: "20%" }}
                  justifyContent={{ xs: "flex-start", sm: "center" }}
                  alignItems="center"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#07a7a1",
                      fontFamily: "'Cormorant Garamond', serif;",
                      pl: { xs: 1 },
                    }}
                  >
                    {item.title}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* </Box> */}
    </Box>
  );
}
