import React, { useState, useRef, useCallback } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  Container,
} from "@mui/material";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [formSubmitted, setFormSumbitted] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const msgRef = useRef();

  const submit = useCallback(() => {
    setNameError(false);
    setEmailError(false);
    setMsgError(false);

    const name = nameRef.current?.value;
    const email = emailRef.current?.value;
    const msg = msgRef.current?.value;

    let validationFailed = false;
    if (name === undefined || name.trim().length === 0) {
      setNameError(true);
      validationFailed = true;
    }
    if (
      email === undefined ||
      email.trim().length === 0 ||
      !/\S+@\S+\.\S+/.test(email)
    ) {
      setEmailError(true);
      validationFailed = true;
    }
    if (msg === undefined || msg.trim().length === 0) {
      setMsgError(true);
      validationFailed = true;
    }
    if (validationFailed) {
      return;
    }

    emailjs
      .send(
        "service_2cjyfy5",
        "template_wu7uxqk",
        { from_name: name, message: msg, contact_info: email },
        "5aYPKcZe2nabGFzWk"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormSumbitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "#110b09",
        height: "auto",
        pb: 20,
        pt: 10,
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sx={{ p: 2, mb: 2, borderRadius: 3 }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h4"
            sx={{
              color: "#e8b169",
              fontFamily: "'Cormorant Garamond', serif;",
            }}
          >
            TURN YOUR CAKE DREAM INTO REALITY
          </Typography>
        </Grid>
        {!formSubmitted && (
          <Container
            maxWidth={"sm"}
            sx={{
              ml: 5,
              mr: 5,
              pt: 2,
              pb: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ bgcolor: "#07a7a1", p: 2, mb: 2, borderRadius: 3 }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                id="name"
                label="Name"
                color="secondary"
                fullWidth
                inputRef={nameRef}
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontFamily: '"Dancing Script", cursive',
                    fontSize: "1.7rem",
                  },
                }}
                error={nameError}
                helperText={nameError ? "Name is required" : ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ bgcolor: "#07a7a1", p: 2, mb: 2, borderRadius: 3 }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                id="email"
                fullWidth
                type="email"
                inputRef={emailRef}
                color="secondary"
                label="Email"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontFamily: '"Dancing Script", cursive',
                    fontSize: "1.7rem",
                  },
                }}
                error={emailError}
                helperText={emailError ? "Invalid email" : ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ bgcolor: "#07a7a1", p: 2, mb: 5, borderRadius: 3 }}
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                id="message"
                label="Message"
                fullWidth
                multiline
                inputRef={msgRef}
                color="secondary"
                rows={4}
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontFamily: '"Dancing Script", cursive',
                    fontSize: "1.7rem",
                  },
                }}
                error={msgError}
                helperText={msgError ? "Message is required" : ""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Button
                color="success"
                sx={{
                  fontFamily: "'Cormorant Garamond', serif;",
                  color: "black",
                  textTransform: "none",
                  fontSize: "1.7rem",
                  fontWeight: "bold",
                  borderRadius: 8,
                }}
                variant="contained"
                onClick={submit}
              >
                Send message
              </Button>
            </Grid>
          </Container>
        )}
        {formSubmitted && (
          <Container
            maxWidth={"sm"}
            sx={{
              ml: 5,
              mr: 5,
              pt: 2,
              pb: 2,
            }}
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h5"
                sx={{
                  color: "#07a7a1",
                  fontFamily: '"Dancing Script", cursive',
                }}
              >
                Thank you for the request!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#07a7a1",
                  fontFamily: '"Dancing Script", cursive',
                }}
              >
                We will contact you soon.
              </Typography>
            </Grid>
          </Container>
        )}
      </Grid>
    </Box>
  );
}
