import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";

const images = [
  {
    name: "Tropicana Cake",
    imgPath: "https://artcake-bucket.s3.amazonaws.com/tropicana.jfif",
    description: "Description 1",
  },
  {
    name: "ChocoBerry Cake",
    imgPath: "https://artcake-bucket.s3.amazonaws.com/chocoberry.jpg",
    description: "Description 2",
  },
  {
    name: "Strawberry Dream Cake",
    imgPath: "https://artcake-bucket.s3.amazonaws.com/strawberry.jpg",
    description: "Description 3",
  },
  {
    name: "Caramel Banana Cake",
    imgPath: "https://artcake-bucket.s3.amazonaws.com/banana.jpg",
    description: "Description 4",
  },
  {
    name: "Snickers Cake",
    imgPath: "https://artcake-bucket.s3.amazonaws.com/snickers.jpg",
    description: "Description 5",
  },
  {
    name: "Arabica Cake",
    imgPath: "https://artcake-bucket.s3.amazonaws.com/arabica.jpg",
    description: "Description 6",
  },
  // {
  //   name: "Cake 7",
  //   imgPath: "",
  //   description: "Description 7",
  // },
  // {
  //   name: "Cake 8",
  //   imgPath: "",
  //   description: "Description 8",
  // },
];

export default function Carousel() {
  const [activeCard, setActiveCard] = useState(0);

  const handleNext = () => {
    setActiveCard((prevActiveCard) => {
      if (prevActiveCard === images.length - 1) {
        return 0;
      }
      return prevActiveCard + 1;
    });
  };

  const handleBack = () => {
    setActiveCard((prevActiveCard) => {
      if (prevActiveCard === 0) {
        return images.length - 1;
      }
      return prevActiveCard - 1;
    });
  };

  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(to bottom, #110b09 10%, #e8b169)",
        // height: "100vh",
        pt: { xs: 4, md: 5 },
      }}
    >
      <Grid
        height={50}
        container
        justifyContent="center"
        alignItems="center"
        // sx={{ bgcolor: "green" }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            color: "#11968d",
            fontFamily: '"Dancing Script", cursive',
          }}
        >
          {images[activeCard].name}
        </Typography>
      </Grid>
      <Grid
        container
        // direction="column"
        justifyContent="center"
        alignItems="center"
        // height={"100vh"}
      >
        <Grid
          item
          xs={2}
          md={3}
          container
          justifyContent="center"
          alignItems="center"
        >
          <IconButton onClick={handleBack}>
            <KeyboardArrowLeft
              sx={{ color: "black", backgroundColor: "#e8b169" }}
            />
          </IconButton>
        </Grid>
        <Grid item xs md container direction="column">
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            // height="90vh"
            sx={{ pb: 2 }}
          >
            <img
              style={{
                borderRadius: "2%",
                maxWidth: "100%",
                maxHeight: "80vh",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={images[activeCard].imgPath}
              alt={images[activeCard].name}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          md={3}
          container
          justifyContent="center"
          alignItems="center"
        >
          <IconButton onClick={handleNext}>
            <KeyboardArrowRight
              sx={{ color: "black", backgroundColor: "#e8b169" }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
