import React, { useState } from "react";
import {
  useMediaQuery,
  Box,
  ImageList,
  ImageListItem,
  Dialog,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const itemData = [
  {
    img: "https://artcake-bucket.s3.amazonaws.com/princess.png",
    title: "Princess",
  },
  {
    img: "https://artcake-bucket.s3.amazonaws.com/tree.jpg",
    title: "Stump",
  },
  {
    img: "https://artcake-bucket.s3.amazonaws.com/fawn.jpg",
    title: "Fawn",
  },
  {
    img: "https://artcake-bucket.s3.amazonaws.com/yoda.jpg",
    title: "Baby Yoda",
  },
  {
    img: "https://artcake-bucket.s3.amazonaws.com/bomb.jpg",
    title: "Bomb",
  },
  {
    img: "https://artcake-bucket.s3.amazonaws.com/lobster.jpg",
    title: "Lobster",
  },
  {
    img: "https://artcake-bucket.s3.amazonaws.com/teddy.jpg",
    title: "Teddy Bear",
  },
  {
    img: "https://artcake-bucket.s3.amazonaws.com/shell.jpg",
    title: "Shell",
  },
];

export default function Gallery() {
  const [selected, setSelected] = useState(undefined);
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setSelected(undefined);
  };

  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(to bottom, #e8b169 1%, #110b09)",
        // height: "100vh",
        p: { xs: 4, md: 5 },
      }}
    >
      <ImageList
        sx={{ width: "100%", height: "100%" }}
        cols={matchDownMd ? 2 : 4}
        rowHeight={"auto"}
      >
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            onClick={() => {
              setSelected({ img: item.img, title: item.title });
            }}
          >
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog
        maxWidth="md"
        fullWidth
        open={selected !== undefined}
        onClose={handleClose}
      >
        <img
          src={selected?.img || ""}
          srcSet={selected?.img || ""}
          alt={selected?.title || ""}
        />
      </Dialog>
    </Box>
  );
}
