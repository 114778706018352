import React, { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CakeIcon from "@mui/icons-material/Cake";
import { Grid, Button } from "@mui/material";

const theme = createTheme({
  typography: {
    fontSize: 14,
    h5: {
      fontFamily: '"Dancing Script", cursive',
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: "#e8b169",
          },
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#11968d",
    },
    secondary: {
      main: "#110b09",
    },
    success: {
      main: "#e8b169",
    },
  },
});

const lessons = [
  {
    title: "МИШКА",
    img: "https://artcake-bucket.s3.amazonaws.com/%D0%BC%D0%B8%D1%88%D0%BA%D0%B0.jpg",
  },
];

export default function LessonsList() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        id="home"
        position="static"
        sx={{
          backgroundImage: "linear-gradient(to right, #110b09 30%, #e8b169)",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <CakeIcon
              sx={{
                color: "#e8b169",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "'Dancing Script', cursive",
                // fontWeight: 700,
                color: "#e8b169",
                textDecoration: "none",
              }}
            >
              ArtCake
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link to="/lessons" style={{ textDecoration: "none" }}>
                <Button
                  //   onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#e8b169",
                    display: "block",
                    fontFamily: "'Cormorant Garamond', serif;",
                  }}
                >
                  Lessons
                </Button>
              </Link>
            </Box>
            <CakeIcon
              sx={{
                display: { color: "#e8b169", xs: "flex", md: "none" },
                mr: 1,
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "'Dancing Script', cursive",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "#e8b169",
                textDecoration: "none",
              }}
            >
              ArtCake
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          backgroundColor: "#110b09",
          height: "100vh",
          p: { xs: 4, md: 5 },
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          {lessons.map((item) => (
            <Grid
              key={item.title}
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              sm={6}
              md={4}
              height={{
                xs: "30vh",
                sm: "30vh",
                md: "40vh",
              }}
              sx={{ p: 2 }}
            >
              <Box
                sx={{
                  backgroundColor: "#e8b169",
                  height: "100%",
                  width: "100%",
                  borderRadius: 5,
                }}
              >
                <Link to="/lessons/teddy-bear">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      maxHeight: "100%",
                      borderRadius: 5,
                    }}
                    src={item.img}
                    alt={item.name}
                  />
                </Link>
              </Box>

              <Typography
                variant="h5"
                to="/lessons/teddy-bear"
                component={Link}
                sx={{
                  color: "#07a7a1",
                  textDecoration: "none",
                  fontFamily: "'Cormorant Garamond', serif;",
                  mt: 2,
                  pl: { xs: 1 },
                }}
              >
                {item.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
