import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import Header from "./Header";
import Carousel from "./Carousel";
import Gallery from "./Gallery";
import Features from "./Features";
import Footer from "./Footer";
import Contact from "./Contact";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CakeIcon from "@mui/icons-material/Cake";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontSize: 14,
    h5: {
      fontFamily: '"Dancing Script", cursive',
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: "#e8b169",
          },
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#11968d",
    },
    secondary: {
      main: "#110b09",
    },
    success: {
      main: "#e8b169",
    },
  },
});

export default function Home() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        id="home"
        position="static"
        sx={{
          backgroundImage: "linear-gradient(to right, #110b09 30%, #e8b169)",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <CakeIcon
              sx={{
                color: "#e8b169",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "'Dancing Script', cursive",
                // fontWeight: 700,
                color: "#e8b169",
                textDecoration: "none",
              }}
            >
              ArtCake
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "#e8b169" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  bgColor: "#e8b169",
                }}
              >
                <HashLink
                  smooth
                  to="/#flavors"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Flavors</Typography>
                  </MenuItem>
                </HashLink>
                <HashLink
                  smooth
                  to="/#design"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Design</Typography>
                  </MenuItem>
                </HashLink>
                <HashLink
                  smooth
                  to="/#gallery"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Gallery</Typography>
                  </MenuItem>
                </HashLink>
                <HashLink
                  smooth
                  to="/#contact"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Contact</Typography>
                  </MenuItem>
                </HashLink>
                <Link
                  to="/lessons"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Lessons</Typography>
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
            <CakeIcon
              sx={{
                display: { color: "#e8b169", xs: "flex", md: "none" },
                mr: 1,
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "'Dancing Script', cursive",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "#e8b169",
                textDecoration: "none",
              }}
            >
              ArtCake
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <HashLink
                smooth
                to="/#flavors"
                style={{ textDecoration: "none" }}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#e8b169",
                    display: "block",
                    fontFamily: "'Cormorant Garamond', serif;",
                  }}
                >
                  Flavors
                </Button>
              </HashLink>
              <HashLink smooth to="/#design" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#e8b169",
                    display: "block",
                    fontFamily: "'Cormorant Garamond', serif;",
                  }}
                >
                  Design
                </Button>
              </HashLink>
              <HashLink
                smooth
                to="/#gallery"
                style={{ textDecoration: "none" }}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#e8b169",
                    display: "block",
                    fontFamily: "'Cormorant Garamond', serif;",
                  }}
                >
                  Gallery
                </Button>
              </HashLink>
              <HashLink
                smooth
                to="/#contact"
                style={{ textDecoration: "none" }}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#e8b169",
                    display: "block",
                    fontFamily: "'Cormorant Garamond', serif;",
                  }}
                >
                  Contact
                </Button>
              </HashLink>
              <Link
                to="/lessons"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#e8b169",
                    display: "block",
                    fontFamily: "'Cormorant Garamond', serif;",
                  }}
                >
                  Lessons
                </Button>
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Header />
      <section id="flavors">
        <Carousel />
      </section>
      <section id="design">
        <Features />
      </section>
      <section id="gallery">
        <Gallery />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <Footer
        instagram={"@artcake.ca"}
        link="https://instagram.com/artcake.ca"
      />
      {scrollPosition !== 0 && (
        <HashLink smooth to="/#home" style={{ textDecoration: "none" }}>
          <Fab
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              color: "black",
            }}
            color="primary"
            size="small"
            aria-label="scroll back to top"
          >
            <KeyboardArrowUp />
          </Fab>
        </HashLink>
      )}
    </ThemeProvider>
  );
}
