import { Button, Box, Grid, Typography } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import Image from "./img/Cake-bg.png"; // Import using relative path

function Header() {
  return (
    <Box
      sx={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "90vh",
        backgroundImage: `url(${Image})`,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          height: "100%",
          pl: { xs: "10%", sm: "7%", md: "5%" },
        }}
      >
        <Grid item sx={{ mb: 4, pr: { xs: "47%" } }}>
          <Typography
            sx={{
              color: "#07a7a1",
              fontSize: { xs: "2em", sm: "2.5em", md: "3em" },
              fontFamily: "'Cormorant Garamond', serif;",
            }}
            variant="h3"
          >
            Cakes that are sure to impress
          </Typography>
        </Grid>
        <Grid item sx={{ pr: { xs: "50%", md: "70%" } }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
              color: "#e8b169",
              mb: 10,
              display: { xs: "none", sm: "block" },
            }}
          >
            <span style={{ whiteSpace: "nowrap" }}>
              Custom-designed desserts that are tailored{" "}
            </span>
            <br />
            <span style={{ whiteSpace: "nowrap" }}>
              to your individual style and taste preferences.
            </span>
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
              color: "#e8b169",
              mb: 10,
              display: { xs: "block", sm: "none" },
            }}
          >
            Custom-designed desserts that are tailored to your individual style
            and taste preferences.
          </Typography>
        </Grid>
        <Grid item>
          <HashLink smooth to="/#contact" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: 8,
                textTransform: "none",
                fontFamily: "'Cormorant Garamond', serif;",
                color: "black",
                fontSize: 17,
                fontWeight: "bold",
              }}
            >
              Turn your cake dream into reality
            </Button>
          </HashLink>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Header;
