import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import CakeIcon from "@mui/icons-material/Cake";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TelegramIcon from "@mui/icons-material/Telegram";

export default function Footer({ instagram, link }) {
  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(to right,  #e8b169 , #110b09 60%)",
        height: { xs: "30vh", md: "25vh" },
      }}
    >
      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={5} sm={8} md={9} />
        <Grid item xs sm md>
          <Typography
            variant="h5"
            sx={{
              mr: 2,
              // mb: 1,
              fontFamily: "'Dancing Script', cursive",
              // fontWeight: 700,
              color: "#e8b169",
              textDecoration: "none",
            }}
          >
            <CakeIcon
              sx={{
                color: "#e8b169",
                mr: 1,
                verticalAlign: "middle",
              }}
            />
            ArtCake
          </Typography>
          <Typography
            variant="body"
            sx={{
              mr: 2,
              mb: 1,
              pl: 4,
              fontFamily: "'Dancing Script', cursive",
              // fontWeight: 700,
              color: "#e8b169",
              textDecoration: "none",
            }}
          >
            by Tanya Klimova
          </Typography>
          <br />
          <br />
          <Typography
            variant="div"
            sx={{
              mr: 2,
              fontFamily: "'Cormorant Garamond', serif;",
              // fontWeight: 700,
              color: "#e8b169",
            }}
          >
            <LocationOnIcon
              sx={{
                color: "#e8b169",
                mr: 1,
                verticalAlign: "middle",
              }}
            />
            Markham, ON, Canada
          </Typography>
          <br />
          <Typography
            variant="div"
            sx={{
              mr: 2,
              fontFamily: "'Cormorant Garamond', serif;",
              // fontWeight: 700,
              color: "#e8b169",
            }}
          >
            <InstagramIcon
              sx={{
                color: "#e8b169",
                mr: 1,
                verticalAlign: "middle",
              }}
            />
            <a href={link} style={{ textDecoration: "none", color: "#e8b169" }}>
              {instagram}
            </a>
          </Typography>
          <br />
          <Typography
            variant="div"
            sx={{
              mr: 2,
              fontFamily: "'Cormorant Garamond', serif;",
              // fontWeight: 700,
              color: "#e8b169",
            }}
          >
            <TelegramIcon
              variant="div"
              sx={{
                color: "#e8b169",
                mr: 1,
                verticalAlign: "middle",
              }}
            />
            <a
              href="https://t.me/tvoe4udo"
              style={{ textDecoration: "none", color: "#e8b169" }}
            >
              Contact on Telegram
            </a>
          </Typography>
          <br />
        </Grid>
      </Grid>
    </Box>
  );
}
