import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import TeddyBear from "./TeddyBear";
import LessonsList from "./LessonsList";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lessons" element={<LessonsList />} />
        <Route path="/lessons/teddy-bear" element={<TeddyBear />} />
      </Routes>
    </Router>
  );
}

export default App;
