import React, { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { Grid, ThemeProvider } from "@mui/material";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CakeIcon from "@mui/icons-material/Cake";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";
import InstagramIcon from "@mui/icons-material/Instagram";
import Footer from "./Footer";

const theme = createTheme({
  typography: {
    fontSize: 14,
    h5: {
      fontFamily: '"Dancing Script", cursive',
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: "#e8b169",
          },
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#11968d",
    },
    secondary: {
      main: "#110b09",
    },
    success: {
      main: "#e8b169",
    },
  },
});

export default function TeddyBear() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        id="home"
        position="static"
        sx={{
          backgroundImage: "linear-gradient(to right, #110b09 30%, #e8b169)",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <CakeIcon
              sx={{
                color: "#e8b169",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "'Dancing Script', cursive",
                // fontWeight: 700,
                color: "#e8b169",
                textDecoration: "none",
              }}
            >
              ArtCake
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link to="/lessons" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#e8b169",
                    display: "block",
                    fontFamily: "'Cormorant Garamond', serif;",
                  }}
                >
                  Lessons
                </Button>
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "#e8b169" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  bgColor: "#e8b169",
                }}
              >
                <Link
                  to="/lessons"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Lessons</Typography>
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
            <CakeIcon
              sx={{
                display: { color: "#e8b169", xs: "flex", md: "none" },
                mr: 1,
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "'Dancing Script', cursive",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "#e8b169",
                textDecoration: "none",
              }}
            >
              ArtCake
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          bgcolor: "#110b09",
          height: "auto",
          pb: 20,
          pt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#07a7a1",
            // fontSize: { xs: "2em", sm: "2.5em", md: "3em" },
            fontFamily: "'Cormorant Garamond', serif;",
            mb: 2,
          }}
          variant="h2"
        >
          Торт Мишка
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          //   sx={{ height: "80vh" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end", md: "flex-end" },
              paddingRight: { xs: 0, sm: 2, md: 10 },
              height: "50vh",
            }}
          >
            <div>
              <img
                src="https://artcake-bucket.s3.amazonaws.com/%D0%BC%D0%B8%D1%88%D0%BA%D0%B0-1.png"
                // width="100%"
                height="100%"
                alt="Мишка"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={10}
            sm={6}
            md={6}
            sx={{ color: "white", pr: 10, justifyContent: "center" }}
          >
            <Typography
              variant="h6"
              sx={{
                paddingLeft: { xs: 5, sm: 0, md: 0 },
                fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
                color: "#e8b169",
                fontFamily: "'Cormorant Garamond', serif;",
              }}
            >
              Привет! В этом уроке вы научитесь готовить торт со вкусом
              "Малиновый трюфель" и оформите его в виде милого плюшевого мишки.
            </Typography>
            <br />
            <Typography
              variant="h6"
              sx={{
                paddingLeft: { xs: 5, sm: 0, md: 0 },
                fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
                color: "#e8b169",
                fontFamily: "'Cormorant Garamond', serif;",
              }}
            >
              Перед приготовлением внимательно изучите все материалы.
            </Typography>
            <br />

            <Typography
              variant="h6"
              sx={{
                paddingLeft: { xs: 5, sm: 0, md: 0 },
                fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
                color: "#e8b169",
                fontFamily: "'Cormorant Garamond', serif;",
              }}
            >
              Присылайте свои вопросы и отмечайте меня на своих работах в
              инстаграме:
              <span style={{ whiteSpace: "nowrap" }}>
                <InstagramIcon
                  sx={{
                    color: "#e8b169",
                    mr: 1,
                    ml: 1,
                    verticalAlign: "middle",
                  }}
                />

                <a
                  href="https://instagram.com/tvoe_4udo"
                  style={{ textDecoration: "none", color: "#e8b169" }}
                >
                  @tvoe_4udo
                </a>
              </span>
            </Typography>
          </Grid>
        </Grid>
        <div style={{ margin: 15, marginTop: 40 }}>
          <a
            href="https://drive.google.com/file/d/1ijdq4d5Yvie33kIsC8a9p2cB9mzcEDAc/view?usp=drive_link"
            style={{ textDecoration: "none" }}
            download={"Инструменты"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: 8,
                textTransform: "none",
                fontFamily: "'Cormorant Garamond', serif;",
                color: "black",
                fontSize: "1.7rem",
                fontWeight: "bold",
              }}
            >
              Список инструментов
            </Button>
          </a>
        </div>
        <div style={{ margin: 15 }}>
          <a
            href="https://drive.google.com/file/d/1ALqivn6Li51gxH515Fu4BUp2K4SX1cq7/view?usp=drive_link"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: 8,
                textTransform: "none",
                fontFamily: "'Cormorant Garamond', serif;",
                // color: "black",
                fontSize: "1.7rem",
                fontWeight: "bold",
              }}
            >
              Рецепт Малиновый трюфель
            </Button>
          </a>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 5,
            mb: 2,
          }}
        >
          Шоколадный бисквит
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/mKHGhPbOb84"
            title="Торт Мишка - Шоколадный бисквит"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            // allowfullscreen
          ></iframe>
        </div>
        <div style={{ margin: 15 }}>
          <a
            href="https://pteat.ru/kalkulyator-ingredientov-dlya-torta-na-druguyu-formu/"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              //   color="success"
              sx={{
                borderRadius: 8,
                textTransform: "none",
                fontFamily: "'Cormorant Garamond', serif;",
                color: "black",
                fontSize: 17,
                // fontSize: "1.7rem",
                fontWeight: "bold",
              }}
            >
              Как пересчитать ингредиенты
            </Button>
          </a>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Малиновое кули
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/UYS0ETR4syI"
            title="Торт Мишка - Малиновое кули"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Шоколадный крем
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/ZYTOT7j_rMw"
            title="Торт мишка - Шоколадный крем"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Сборка торта
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/9AuvTxWCDnw"
            title="Торт Мишка - Сборка торта"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Масса для лепки
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/9m11xd2deO8"
            title="Торт Мишка - Сборка торта"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Форма торта
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/CaoPN89DsxY"
            title="Торт Мишка - Форма"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <div style={{ margin: 15 }}>
          <a
            href="https://drive.google.com/file/d/1Bf3GTgFYGgcdarcU7nm6M1xULq_32BY7/view?usp=drive_link"
            style={{ textDecoration: "none", marginRight: 1, marginLeft: 1 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              //   color="success"
              sx={{
                borderRadius: 8,
                textTransform: "none",
                fontFamily: "'Cormorant Garamond', serif;",
                color: "black",
                fontSize: 17,
                marginRight: 1,
                marginLeft: 1,
                // fontSize: "1.7rem",
                fontWeight: "bold",
              }}
            >
              Референс
            </Button>
          </a>

          <a
            href="https://drive.google.com/file/d/1GZgOxm7Uc7_xlpdcFdN7a3-XiztihoVL/view?usp=drive_link"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="success"
              sx={{
                borderRadius: 8,
                textTransform: "none",
                fontFamily: "'Cormorant Garamond', serif;",
                marginRight: 1,
                marginLeft: 1,
                // color: "black",
                fontSize: 17,
                // fontSize: "1.7rem",
                fontWeight: "bold",
              }}
            >
              Шаблон
            </Button>
          </a>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Шоколадный ганаш для покрытия
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/ZD4EqFUEb_U"
            title="Торт Мишка - Шоколадный ганаш"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Покрытие
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/WpGfzB6cfAY"
            title="Покрытие"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Окрашивание
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/WV7dYdcTNqY"
            title="Торт Мишка - Окрашивание"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <div style={{ margin: 15 }}>
          <a
            href="https://lookcolor.ru/kak_poluchit_cvet/kak-poluchit-korichnevyj/"
            style={{ textDecoration: "none", marginRight: 1, marginLeft: 1 }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              //   color="success"
              sx={{
                borderRadius: 8,
                textTransform: "none",
                fontFamily: "'Cormorant Garamond', serif;",
                color: "black",
                fontSize: 17,
                marginRight: 1,
                marginLeft: 1,
                // fontSize: "1.7rem",
                fontWeight: "bold",
              }}
            >
              Как получить оттенки коричневого из других цветов
            </Button>
          </a>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Детализация
        </Typography>
        <div>
          <iframe
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
            src="https://www.youtube.com/embed/tbKDxu0Bi9k"
            title="Детализация"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
            allowfullscreen
          ></iframe>
        </div>
        <Typography
          variant="h4"
          sx={{
            // fontSize: { xs: "1.2em", sm: "1.3em", md: "1.5em" },
            color: "#e8b169",
            fontFamily: "'Cormorant Garamond', serif;",
            mt: 3,
            mb: 2,
          }}
        >
          Результат
        </Typography>
        <Box
          justifyContent={"center"}
          sx={{ display: { xs: "none", md: "inherit" } }}
        >
          <img
            style={{
              // objectFit: "cover",
              width: "50%",
              borderRadius: 5,
            }}
            src="https://artcake-bucket.s3.amazonaws.com/%D0%BC%D0%B8%D1%88%D0%BA%D0%B0.jpg"
            alt="Готовый торт Мишка"
          />
        </Box>
        <Box
          justifyContent={"center"}
          sx={{ display: { xs: "inherit", sm: "inherit", md: "none" } }}
        >
          <img
            style={{
              // objectFit: "cover",
              width: "90%",
              borderRadius: 5,
            }}
            src="https://artcake-bucket.s3.amazonaws.com/%D0%BC%D0%B8%D1%88%D0%BA%D0%B0.jpg"
            alt="Готовый торт Мишка"
          />
        </Box>
      </Box>
      <Footer instagram={"@tvoe_4udo"} link="https://instagram.com/tvoe_4udo" />
    </ThemeProvider>
  );
}
